import { useEffect, useState } from "react";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import { db, auth, storage } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import {
    addDashAndSmallLetters,
    addLinebreaks,
} from "./textInputFunctions/tiFunctions";

export default function CreatePost({ isAuth, titles }) {
    const [mainTitle, setMainTitle] = useState("");
    const [subTitle, setSubTitle] = useState("");
    const [titleUrl, setTitleUrl] = useState("");
    const [postText, setPostText] = useState("");
    const [nextArticle, setNextArticle] = useState(null);
    const [imageUpload, setImageUpload] = useState(null);
    const [fileUrl, setFileUrl] = useState("");
    const createdAt = serverTimestamp();

    let navigate = useNavigate();

    const blogPostsRef = collection(db, "blogposts");
    const titleRef = collection(db, "titles");

    useEffect(() => {
        if (!isAuth) {
            navigate("/login");
        }
    });

    const uploadImage = () => {
        if (imageUpload == null) {
            return;
        } else {
            const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
            uploadBytes(imageRef, imageUpload)
                .then((snapshot) => {
                    const picRef = ref(storage, snapshot.metadata.fullPath);
                    getDownloadURL(picRef).then((url) => {
                        setFileUrl(url);
                    });
                })
                .catch((error) => console.log("error: ", error));
        }
    };

    const textEdit = (text) => {
        const textWithLB = addLinebreaks(text);
        setPostText(textWithLB);
    };

    const clickEvent = async () => {
        await addDoc(blogPostsRef, {
            nextArticle,
            titleUrl,
            mainTitle,
            subTitle,
            fileUrl,
            postText,
            author: {
                name: auth.currentUser.displayName,
                id: auth.currentUser.uid,
            },
            createdAt,
        });
        await addDoc(titleRef, {
            titleUrl,
            mainTitle,
            subTitle,
            fileUrl,
            createdAt,
        });
        navigate("/");
    };

    return (
        <>
            <div className="createPostPage">
                <div className="cpContainer">
                    <h1>Create A Post</h1>
                    {!fileUrl && (
                        <div className="fileUpload">
                            <input
                                type="file"
                                onChange={(e) => {
                                    setImageUpload(e.target.files[0]);
                                }}
                            />
                            <button onClick={uploadImage}>Upload File</button>
                        </div>
                    )}

                    <div className="container-textarea-main-title">
                        <label> Title:</label>
                        <input
                            className="textarea-main-title"
                            placeholder="Title?"
                            onChange={(event) => {
                                setMainTitle(event.target.value);
                                setTitleUrl(
                                    addDashAndSmallLetters(event.target.value)
                                );
                            }}
                        ></input>
                    </div>
                    <div className="container-textarea-sub-title">
                        <label> Sub Title:</label>

                        <input
                            className="textarea-sub-title"
                            placeholder="Sub title?"
                            onChange={(event) =>
                                setSubTitle(event.target.value)
                            }
                        ></input>
                    </div>
                    <div className="inputGp text-black">
                        <label> Post:</label>
                        <textarea
                            className="textarea-createpost"
                            placeholder="Post?"
                            onChange={(event) => textEdit(event.target.value)}
                        ></textarea>
                    </div>
                    <div className="link-to-next">
                        {!nextArticle &&
                            titles.map((title) => {
                                return (
                                    <div
                                        key={title.id}
                                        onClick={() => setNextArticle(title)}
                                    >
                                        {title.mainTitle}
                                    </div>
                                );
                            })}
                        {nextArticle && (
                            <div onClick={() => setNextArticle(null)}>
                                Chosen next article: {nextArticle.mainTitle}
                            </div>
                        )}
                    </div>
                    {mainTitle && subTitle && postText && fileUrl && (
                        <button onClick={clickEvent}>Submit</button>
                    )}
                </div>
                <div className="demoText">
                    <div className="main-title-article">{mainTitle}</div>
                    <div className="sub-title-article">{subTitle}</div>
                    <div className="main-text">{postText}</div>
                </div>
            </div>
        </>
    );
}
