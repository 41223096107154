import { auth, provider } from "../firebase-config";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

export default function Login({ setIsAuth }) {
    let navigate = useNavigate();

    const signInWithGoogle = () => {
        signInWithPopup(auth, provider).then((result) => {
            localStorage.setItem("isAuth", true);
            setIsAuth(true);

            navigate("/competition");
        });
    };

    return (
        <>
            <div className="loginPage">
                <p> Sign in with Google</p>
                <button
                    className="login-with-google-btn"
                    onClick={signInWithGoogle}
                >
                    Sign in
                </button>
            </div>
        </>
    );
}
