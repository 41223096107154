import Obfuscate from "react-obfuscate";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

export default function Impressum() {
    const [impres, setImpres] = useState({});

    const emailRef = doc(db, "email", "PnQggeSVyFqUw9YVx7Mt");

    useEffect(() => {
        const getItem = async () => {
            const docSnap = await getDoc(emailRef);
            if (docSnap.exists()) {
                setImpres(docSnap.data());
            } else {
                console.log("error on loading impressum from DB");
            }
        };
        getItem();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (!impres.adres) {
        return (
            <>
                <div>loading...</div>
            </>
        );
    } else {
        return (
            <>
                <div>Jeroen Nepveu</div>
                <div>Routesetter</div>
                <div>
                    tel:
                    <Obfuscate
                        tel={atob(impres.phone1) + "830" + atob(impres.phone2)}
                    ></Obfuscate>
                </div>
                <div>
                    <Obfuscate
                        email={
                            atob(impres.emailEncrypt) +
                            "@gm" +
                            atob(impres.emailEncryptP2)
                        }
                    />
                </div>
                <div>mail: R{atob(impres.adres)}erlin, Germany</div>
                <br></br>
                <div>Concept Setting (owner Jeroen Nepveu)</div>
                <div>Gri{atob(impres.adres2)}chede, The Netherlands</div>
                <div>CCI (KvK): {atob(impres.kvk)}369</div>
                <div>VAT: N{atob(impres.vat)}2B01</div>
                <div></div>
            </>
        );
    }
}
