import calculateTotalResultAthlete from "./calculateTotalResultsAthlete";
import sortAthletesArray from "./sortAthletesArray";

export default function ViewField({ athletesArray }) {
    if (athletesArray) {
        let results = [];

        athletesArray.forEach((athlete) => {
            const total = calculateTotalResultAthlete(athlete);
            results.push({ ...athlete, total });
        });
        results = sortAthletesArray(results);

        return (
            <>
                <div>
                    {results.map((athlete) => {
                        return (
                            <div key={athlete.id}>
                                <div id="climbername">{athlete.name}</div>
                                <div id="results-boulder">
                                    <div>
                                        <div id="total">Total</div>
                                        <div>
                                            Tops: {athlete.total.tops}/
                                            {athlete.total.attemptsForTops}
                                        </div>
                                        <div>
                                            Zones: {athlete.total.zones}/
                                            {athlete.total.attemptsForZones}
                                        </div>
                                    </div>

                                    <div id="boulders-rounds">
                                        {athlete.boulders.map((boulder) => {
                                            return (
                                                <div
                                                    className="boulder-result"
                                                    key={boulder.id}
                                                >
                                                    <div className="b-num">
                                                        B {boulder.id + 1}
                                                    </div>
                                                    <div>
                                                        Z{boulder.zoneTries}
                                                    </div>
                                                    <div>
                                                        T{boulder.topTries}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
}
