import gaOptout from "./randomFunctions/optOutGA";

export default function CookieBanner({ setCookieBanner }) {
    const acceptClick = () => {
        localStorage.setItem("cookies?", "accepted");
        setCookieBanner("accepted");
    };

    const onlyNecessaryClick = () => {
        localStorage.setItem("cookies?", "necessary");
        gaOptout();
        setCookieBanner("necessary");
    };

    return (
        <>
            <div className="cookie-banner">
                <div className="cookie-text">
                    This website uses cookies. Some cookies are essential for
                    the operation of our site, while others help to understand
                    how users experience this website. By providing insights
                    into how this site is being used by Google Analytics. By
                    clicking "Accept & continue", you consent to the use of all
                    cookies.
                </div>
                <div className="cookie-options">
                    <button className="cookie-button" onClick={() => acceptClick()}>
                        Accept & continue
                    </button>
                    <button className="cookie-button" onClick={() => onlyNecessaryClick()}>
                        Only necessary cookies & continue
                    </button>
                </div>
            </div>
        </>
    );
}
