import pic from "./images/pic.jpg";

export default function About() {
    return (
        <>
            <div>
                <h1>About Concept Setting</h1>
                <p>
                    Concept setting is a website to share my personal experience
                    as a routesetter as well as trying to grasp more fundamental
                    concepts around route setting and all things related to it.
                    The goal is to educate and start discussions around the
                    routesetting profession, so we can maybe improve our shared
                    understanding, work ethics and conditions.
                    <br></br>
                    I, like many of of the people that I have and am working
                    with love the setter job. With conceptsetting.com I am trying
                    to talk with other setters as well as other professions to
                    see if we can better understand what we are doing. I do not
                    assume I have all knowledge or that I make a good example of
                    what a professional routesetter should be. I just want to
                    gather more information and see if we can improve some
                    aspects of it. So if you have a response to
                    anything I wrote, please let me know!
                </p>
                <h1> About me</h1>

                <p>
                    I am Jeroen Nepveu, a Dutch routesetter residing in Berlin.
                    For well over a decade I have been a routesetter and in 2015
                    I went professional as a freelancer. Before I became a
                    routesetter I studied Social Work in Enschede, the
                    Netherlands. Although enjoying parts of my study, I became
                    more and more obsessed with routesetting and trying to
                    understand climbing movement. With some luck along the way I got picked up
                    by a collective of setters, from one moment to the next my
                    life changed to traveling and setting for a lot of different
                    gyms and comeptitions. 
                    <br></br>Over time I learned the routesetting craft by
                    setting a lot, making loads of mistakes, working with a lot
                    of people and doing every job that I could get. In this time
                    I have set as a competition (chief-)routesetter for all
                    levels of competition; from small local competitions to
                    national and some international master competitions. Besides
                    this I have set as a freelance (chief-)setter for gyms in
                    multiple places. For a while I did way too much, so in
                    recent years I have lowered the amount of route setting and
                    learned programming for web development. Now, besides
                    setting, I am trying to share my route setting experience
                    and knowledge through workshops, coaching, consultancy and
                    now also this website!
                </p>
                <img
                    id="pic-one"
                    src={pic}
                    alt="routesetting for local fun competition final. I look a little angry when I am focussed"
                ></img>
            </div>
        </>
    );
}
