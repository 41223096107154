import "./App.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

// import Competition from "./pages/competition/Competition";

import Home from "./pages/Home";
import CreatePost from "./pages/CreatePost";
import Login from "./pages/Login";
import About from "./pages/About";
import Messages from "./pages/Messages";
import ArticlesList from "./pages/ArticlesList";
import Article from "./pages/Article";
import NavBar from "./pages/NavBar";
import { db } from "./firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { dateInString } from "./pages/randomFunctions/randomFunctions";
import TempContact from "./pages/TempContact";
import Impressum from "./pages/Impressum2";
import CookieBanner from "./pages/CookieBanner";
import gaOptout from "./pages/randomFunctions/optOutGA";
import FinalsApp from "./pages/finals-app/FinalsApp";

function App() {
    const [cookieBanner, setCookieBanner] = useState(
        localStorage.getItem("cookies?")
    );

    const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
    const [titles, setTitles] = useState([]);
    const [selectedTitle, setSelectedTitle] = useState("");
    const titlesRef = collection(db, "titles");

    useEffect(() => {
        const getTitles = async () => {
            const data = await getDocs(titlesRef);
            const sortedData = data.docs.sort(
                (a, b) => b.data().createdAt - a.data().createdAt
            );
            const formattedTitles = sortedData.map((title) => {
                const formattedTitle = dateInString(title.data());
                return { ...formattedTitle, id: title.id };
            });
            setTitles(formattedTitles);
        };
        getTitles();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (cookieBanner === "necessary") {
        gaOptout();
    }

    return (
        <div className="App">
            {!cookieBanner && (
                <CookieBanner setCookieBanner={setCookieBanner}></CookieBanner>
            )}

            <BrowserRouter>
                <>
                    <header className="header-full">
                        <Link to="/">
                            <div className="header-full-title">
                                <div className="header-maintitle">
                                    CONCEPT SETTING
                                </div>
                                <div className="header-subtitle">
                                    GRASPING THE CONCEPTS OF ROUTESETTING
                                </div>
                            </div>
                        </Link>
                        <NavBar isAuth={isAuth} setIsAuth={setIsAuth}></NavBar>
                    </header>
                    <section className="middle-content">
                        <aside className="left-sidebar"></aside>

                        <main className="main-content">
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Home isAuth={isAuth} />}
                                />

                                <Route
                                    path="/createpost"
                                    element={
                                        <CreatePost
                                            isAuth={isAuth}
                                            titles={titles}
                                        />
                                    }
                                />
                                <Route
                                    path="/login/"
                                    element={<Login setIsAuth={setIsAuth} />}
                                />
                                <Route
                                    path={"article/:selected"}
                                    element={
                                        <Article
                                            selectedTitle={selectedTitle}
                                            isAuth={isAuth}
                                        />
                                    }
                                ></Route>
                                <Route
                                    path="/about"
                                    element={<About />}
                                ></Route>
                                <Route
                                    path="/articles"
                                    element={
                                        <ArticlesList
                                            titles={titles}
                                            setSelectedTitle={setSelectedTitle}
                                        />
                                    }
                                ></Route>
                                {/* <Route
        path="/contact"
        element={<ContactForm />}
    ></Route>{" "} */}
                                <Route
                                    path="/contact"
                                    element={<TempContact />}
                                ></Route>
                                <Route
                                    path="/messages"
                                    element={<Messages isAuth={isAuth} />}
                                ></Route>
                                <Route
                                    path="/impressum"
                                    element={<Impressum />}
                                ></Route>
                                <Route
                                    path="/finals//*"
                                    element={<FinalsApp />}
                                ></Route>
                            </Routes>
                        </main>
                        <aside className="right-sidebar"></aside>
                    </section>
                </>

                <footer>
                    © Jeroen Nepveu 2023 All rights reserved
                    <Link to="/impressum">
                        <button>IMPRESSUM</button>
                    </Link>
                </footer>
            </BrowserRouter>
        </div>
    );
}

export default App;
