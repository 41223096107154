export default function sortAthletesArray(athletesArray) {
    athletesArray = [...athletesArray];
    
    // First sort by tops
    athletesArray.sort((a, b) => b.tops - a.tops);

    // If there are ties, sort by zones
    const topsLeaderboard = athletesArray.filter(
        (athlete) => athlete.tops === athletesArray[0].tops
    );
    if (topsLeaderboard.length > 1) {
        topsLeaderboard.sort((a, b) => b.zones - a.zones);
    }

    // If there are still ties, sort by attemptsForTops
    const zonesLeaderboard = topsLeaderboard.filter(
        (athlete) => athlete.zones === topsLeaderboard[0].zones
    );
    if (zonesLeaderboard.length > 1) {
        zonesLeaderboard.sort((a, b) => a.attemptsForTops - b.attemptsForTops);
    }

    // If there are still ties, sort by attemptsForZones
    const attemptsForTopsLeaderboard = zonesLeaderboard.filter(
        (athlete) =>
            athlete.attemptsForTops === zonesLeaderboard[0].attemptsForTops
    );
    if (attemptsForTopsLeaderboard.length > 1) {
        attemptsForTopsLeaderboard.sort(
            (a, b) => a.attemptsForZones - b.attemptsForZones
        );
    }

    return athletesArray;
}
