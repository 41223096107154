import { NavLink } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../firebase-config";

export default function NavBar({ isAuth, setIsAuth }) {
    const signOutFunction = () => {
        signOut(auth).then(() => {
            localStorage.clear();
            setIsAuth(false);
        });
    };

    let activeStyle = {
        borderTop: "3px solid",
        paddingTop: "4px",
        borderBottom: "3px solid",
        paddingBottom: "6px",
        margin: "0% 1% 0%",
    };

    let nonActiveStyle = {
        paddingTop: "8px",
        paddingBottom: "8px",
        margin: "0% 1% 0%",
    };

    return (
        <>
            <nav className="nav-bar">
                <NavLink
                    style={({ isActive }) =>
                        isActive ? activeStyle : nonActiveStyle
                    }
                    to="/"
                >
                    Home
                </NavLink>
                <NavLink
                    style={({ isActive }) =>
                        isActive ? activeStyle : nonActiveStyle
                    }
                    to="/articles"
                >
                    Articles
                </NavLink>
                {!isAuth ? (
                    <NavLink
                        style={({ isActive }) =>
                            isActive ? activeStyle : nonActiveStyle
                        }
                        to="/about"
                    >
                        About
                    </NavLink>
                ) : (
                    <div className="isAuthTrue">
                        <NavLink
                            style={({ isActive }) =>
                                isActive ? activeStyle : nonActiveStyle
                            }
                            to="/createpost"
                        >
                            Create Post
                        </NavLink>
                        <NavLink
                            style={({ isActive }) =>
                                isActive ? activeStyle : nonActiveStyle
                            }
                            to="/messages"
                        >
                            Messages
                        </NavLink>
                        <button onClick={signOutFunction}>Log out</button>
                    </div>
                )}
                <NavLink
                    style={({ isActive }) =>
                        isActive ? activeStyle : nonActiveStyle
                    }
                    to="/contact"
                >
                    Contact
                </NavLink>
            </nav>
        </>
    );
}
