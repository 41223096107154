import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { dateInString } from "./randomFunctions/randomFunctions";

export default function Messages({ isAuth }) {
    const [messages, setMessages] = useState([]);

    let navigate = useNavigate();
    const messagesRef = collection(db, "messages");

    useEffect(() => {
        if (!isAuth) {
            navigate("/login");
        }

        const getMessages = async () => {
            const data = await getDocs(messagesRef);
            const sortedData = data.docs.sort(
                (a, b) => b.data().createdAt - a.data().createdAt
            );
            const formattedMessages = sortedData.map((message) => {
                const formattedMessage = dateInString(message.data());
                return { ...formattedMessage, id: message.id };
            });
            setMessages(formattedMessages);
        };
        getMessages();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {messages.map((message) => {
                return (
                    <div className="single-message" key={message.id}>
                        <div>{message.fullName}</div>
                        <div>{message.email}</div>
                        <div>{message.createdAt}</div>
                        <div>{message.message}</div>
                    </div>
                );
            })}
        </>
    );
}
