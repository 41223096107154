import { Routes, Route} from "react-router-dom";

import AthleteCreation from "./create-final.js/athlete-creation/AthletesCreation";
import FinalsCreation from "./create-final.js/finals-creation/FinalsCreation";
import { useState } from "react";
import AthleteConsole from "./athlete-console/AthleteConsole";
import ViewField from "./view-field/ViewField";
import ViewAthletes from "./view-athletes/ViewAthletes";
import { useNavigate } from "react-router-dom";

export default function FinalsApp() {
    const [final] = useState(JSON.parse(localStorage.getItem("final")));
    const [athletesArray, setAthletesArray] = useState(
        JSON.parse(localStorage.getItem(final.categoryName))
    );
    const [athleteObject, setAthleteObject] = useState("");
    const navigate = useNavigate();

    const clickAthlete = (athlete) => {
        setAthleteObject(athlete);
        navigate("finals/athlete-console");
    };
    

    return (
        <>
            <div>
                test
                <Routes>
                    <Route
                        path="/create-athlete"
                        element={
                            <AthleteCreation
                                setAthletesArray={setAthletesArray}
                            />
                        }
                    />
                    <Route path="/create-final" element={<FinalsCreation />} />
                    <Route
                        path="/view-field"
                        element={<ViewField athletesArray={athletesArray} />}
                    />
                    <Route
                        path="/view-athletes"
                        element={
                            <ViewAthletes
                                clickAthlete={clickAthlete}
                                athletesArray={athletesArray}
                            />
                        }
                    />
                    <Route
                        path="/athlete-console"
                        element={
                            <AthleteConsole
                                setAthleteObject={setAthleteObject}
                                athleteObject={athleteObject}
                                athletesArray={athletesArray}
                                setAthletesArray={setAthletesArray}
                            />
                        }
                    />
                </Routes>
            </div>
        </>
    );
}
