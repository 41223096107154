import { useState } from "react";

export default function AthleteCreation({ addAthlete }) {
    const [name, setName] = useState("");

    const handleChange = (event) => {
        setName(event.target.value);
    };

    const handleClick = (name) => {
        addAthlete(name);
        setName("");
    };

    return (
        <div>
            <input type="text" value={name} onChange={handleChange} />
            <button onClick={() => handleClick(name)}>Add Name</button>
        </div>
    );
}
