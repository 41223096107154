import { useState } from "react";

export default function BoulderEdit({ boulder, onSave }) {
    const [boulderState, setBoulderState] = useState(boulder);

    const handleAddAttempt = () => {
        const updatedBoulder = {
            ...boulderState,
            boulderAttempts: boulderState.boulderAttempts + 1,
        };
        setBoulderState(updatedBoulder);
    };

    const handleRemoveAttempt = () => {
        const updatedBoulder = {
            ...boulderState,
            boulderAttempts: Math.max(0, boulderState.boulderAttempts - 1),
        };
        setBoulderState(updatedBoulder);
    };

    const handleSetZone = () => {
        const updatedBoulder = {
            ...boulderState,
            gotZone: true,
            zoneTries: boulderState.boulderAttempts,
        };
        setBoulderState(updatedBoulder);
    };

    const handleRemoveZone = () => {
        const updatedBoulder = {
            ...boulderState,
            gotZone: false,
            zoneTries: null,
        };
        setBoulderState(updatedBoulder);
    };

    const handleSetTop = () => {
        const updatedBoulder = {
            ...boulderState,
            gotTop: true,
            topTries: boulderState.boulderAttempts,
        };
        setBoulderState(updatedBoulder);
    };

    const handleRemoveTop = () => {
        const updatedBoulder = {
            ...boulderState,
            gotTop: false,
            topTries: null,
        };
        setBoulderState(updatedBoulder);
    };

    const handleSaveClick = () => {
        onSave(boulderState);
    };

    return (
        <div>
            <button onClick={handleSaveClick}>Save Boulder</button>
            <h2>Boulder {boulder.id}</h2>
            {!boulderState.gotTop && <h3>No Top</h3>}
            {boulderState.gotTop && <h3>Top in: {boulderState.topTries}</h3>}
            {!boulderState.gotTop && (
                <button onClick={handleSetTop}>Set Top</button>
            )}
            {boulderState.gotTop && (
                <button onClick={handleRemoveTop}>Remove Top</button>
            )}
            {!boulderState.gotZone && <h3>No Zone</h3>}
            {boulderState.gotZone && <h3>Zone in: {boulderState.zoneTries}</h3>}
            {!boulderState.gotZone && (
                <button onClick={handleSetZone}>Set Zone</button>
            )}
            {boulderState.gotZone && (
                <button onClick={handleRemoveZone}>Remove Zone</button>
            )}
            <h3>Attempts: {boulderState.boulderAttempts}</h3>
            <div>
                {!boulderState.gotTop && (
                    <button onClick={handleAddAttempt}>Add Attempt</button>
                )}
                {boulderState.boulderAttempts > 0 && (
                    <button onClick={handleRemoveAttempt}>
                        Remove Attempt
                    </button>
                )}
            </div>
        </div>
    );
}
