import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

import Obfuscate from "react-obfuscate";

export default function TempContact() {
    const [encryptEmail, setEncryptEmail] = useState("");
    const [p2, setP2] = useState("");
    const [error, setError] = useState(false);

    const emailRef = doc(db, "email", "PnQggeSVyFqUw9YVx7Mt");

    useEffect(() => {
        const getItem = async () => {
            const docSnap = await getDoc(emailRef);

            if (docSnap.exists()) {
                setError(false);
                setEncryptEmail(docSnap.data().emailEncrypt);
                setP2(docSnap.data().emailEncryptP2);
            } else {
                setError(true);
            }
        };
        getItem();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="contact-full">
                <br></br>
                <div className="contact">
                    For remarks, questions or anything else (except spam me with
                    stuff) don't hesitate to get in contact with me!
                </div>
                <div className="contact">
                    You can contact me through&nbsp;
                    <span className="contact-link">
                        <Link href="https://www.instagram.com/jeroennepv/">
                            Instagram
                        </Link>
                    </span>
                </div>

                {!error && encryptEmail && p2 && (
                    <div className="contact">
                        Or you can email me at:
                        <span className="contact-link">
                            <Obfuscate
                                email={atob(encryptEmail) + "@gm" + atob(p2)}
                            />
                        </span>
                    </div>
                )}
                <br></br>
                <div className="contact">
                    For additional contact information:&nbsp;
                    <Link to="../impressum">
                        <span className="contact-link">Impressum</span>
                    </Link>
                </div>
            </div>
        </>
    );
}
