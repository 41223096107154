export default function ViewAthletes({ athletesArray, clickAthlete }) {
    return (
        <>
           
            <div>
                <div>
                    {athletesArray.map((athlete) => {
                        return (
                            <div
                                onClick={() => clickAthlete(athlete)}
                                key={athlete.id}
                            >
                                {athlete.name}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
