export default function makeBouldersObject(boulderAmount) {
    console.log("boulderAmount: ", boulderAmount);

    let boulders = [];

    for (let i = 0; i < boulderAmount; i++) {
        const boulder = {
            id: i,
            zoneTries: 0,
            gotZone: false,
            topTries: 0,
            gotTop: false,
            boulderAttempts: 0,
        };
        boulders.push(boulder);
    }

    return boulders;
}
