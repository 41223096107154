/**
 * tiFunctions: Text Input Functions
 *
 * These functions edit text for the database and back
 *
 */

export const addLinebreaks = (string) => {
    return string.replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export const addDashAndSmallLetters = (string) => {
    return string.replace(/\s/g, "-").toLowerCase();
};

export const removeDashForWhiteSpace = (string) => {
    return string.replace(/-/g, " ");
};

export const nameCheck = (string) => {
    if (string.length <= 3 || string.length >= 50) {
        return false;
    } else {
        return true;
    }
};

export const emailCheck = (string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(string) && string.length <= 255;
};

export const messageCheck = (string) => {
    if (string.length <= 20 || string.length >= 1000) {
        return false;
    } else return true;
};
