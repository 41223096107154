import { useState } from "react";
import BoulderEdit from "../boulder-edit/BoulderEdit";

export default function AthleteConsole({
    setAthleteObject,
    athleteObject,
    athletesArray,
    setAthletesArray,
}) {
    const { name, boulders } = athleteObject;
    const [editingBoulder, setEditingBoulder] = useState(null);

    const handleBoulderClick = (id) => {
        const boulder = boulders[id];
        setEditingBoulder(boulder);
    };
    console.log("athleteObject.boulders: ", athleteObject.boulders);

    const handleSaveBoulder = (updatedBoulder) => {
        const updatedBoulders = {
            ...athleteObject.boulders,
            [updatedBoulder.id]: updatedBoulder,
        };
        // fix athlete control!!

        const updatedAthlete = {
            ...athleteObject,
            boulders: updatedBoulders,
        };
        console.log("updatedAthlete: ", updatedAthlete);
        const athleteIndex = athletesArray.findIndex(
            (a) => a.id === athleteObject.id
        );
        const updatedAthletesArray = [...athletesArray];
        updatedAthletesArray[athleteIndex] = updatedAthlete;

        setAthletesArray(updatedAthletesArray);
        setEditingBoulder(null);
        setAthleteObject("");
    };

    return (
        <>
            <div>
                <div>
                    <h1>{name}</h1>
                </div>
                {!editingBoulder &&
                    Object.keys(boulders).map((id) => (
                        <button key={id} onClick={() => handleBoulderClick(id)}>
                            {`Boulder ${id}`}
                        </button>
                    ))}
                {editingBoulder && (
                    <BoulderEdit
                        boulder={editingBoulder}
                        onSave={handleSaveBoulder}
                    />
                )}
            </div>
        </>
    );
}
