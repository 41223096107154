import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function FinalsCreation() {
    const navigate = useNavigate();

    const [competitionName, setCompetitionName] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [boulderAmount, setBoulderAmount] = useState(4);
    const [finalFormat] = useState("worldcup");

    const handleSubmit = (e) => {
        e.preventDefault();
        const final = {
            competitionName,
            categoryName,
            boulderAmount,
            finalFormat,
        };

        addComp(final);
        navigate("/finals/create-athlete");
    };

    const addComp = (final) => {
        localStorage.setItem("final", JSON.stringify(final));
    };

    return (
        <>
            <form>
                <div className="input">
                    <label htmlFor="name">Add competition name:</label>
                    <input
                        id="name"
                        type="text"
                        value={competitionName}
                        onChange={(e) => setCompetitionName(e.target.value)}
                    />
                </div>
                <div className="input">
                    <label htmlFor="category">Add a category:</label>
                    <div>(for example: male, female, open...):</div>
                    <input
                        id="category"
                        type="text"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                </div>
                <div className="input">
                    <label htmlFor="boulder-amount">
                        Amount of final boulders:
                    </label>
                    <input
                        id="boulder-amount"
                        type="number"
                        value={boulderAmount}
                        onChange={(e) => setBoulderAmount(e.target.value)}
                    />
                </div>
                <button onClick={(e) => handleSubmit(e)}>
                    Create the Final
                </button>
            </form>
        </>
    );
}
