export default function calculateTotalResultAthlete(athlete) {
    const boulders = athlete.boulders;

    const athleteResults = {
        totalAttempts: 0,
        attemptsForTops: 0,
        attemptsForZones: 0,
        tops: 0,
        zones: 0,
        boulders: { ...boulders },
    };

    // Loop through each boulder for this athlete
    Object.values(boulders).forEach((boulder) => {
        athleteResults.totalAttempts += boulder.boulderAttempts;

        if (boulder.gotTop) {
            athleteResults.tops += 1;
            athleteResults.attemptsForTops += boulder.topTries;
        }
        if (boulder.gotZone) {
            athleteResults.zones += 1;
            athleteResults.attemptsForZones += boulder.zoneTries;
        }
    });

    return athleteResults;
}
