import { Timestamp } from "firebase/firestore";

export const nameChanger = (string) => {
    if (string === "Yabelly N") {
        return (string = "Jeroen");
    }
};

export const dateChanger = (sec, nanoSec) => {
    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const date = new Timestamp(sec, nanoSec).toDate();
    const formatter = new Intl.DateTimeFormat(undefined, options);
    const formattedDate = formatter.format(date);
    return formattedDate;
};

export const dateInString = (obj) => {
    const copy = { ...obj };
    const createdAt = copy.createdAt.toDate();
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const day = createdAt.getDate();
    const month = months[createdAt.getMonth()];
    const year = createdAt.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;
    return {
        ...copy,
        createdAt: formattedDate,
    };
};
