import { getDocs, collection, query, orderBy, limit } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase-config";
import { dateInString } from "./randomFunctions/randomFunctions";
import { Link } from "react-router-dom";
// import Competition from "./competition/Competition";

export default function Home({ isAuth, titles, setSelectedTitle }) {
    // comp setting

    //comp setting

    const [lastPost, setLastPost] = useState({});

    const blogPostsRef = collection(db, "blogposts");
    const q = query(blogPostsRef, orderBy("createdAt", "desc"), limit(1));

    useEffect(() => {
        const getLastPost = async () => {
            const documentSnapshot = await getDocs(q);
            if (!documentSnapshot.empty) {
                const document = documentSnapshot.docs[0];
                const datedDocument = dateInString(document.data());
                setLastPost({ id: document.id, ...datedDocument });
            }
        };
        getLastPost();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (JSON.stringify(lastPost) === "{}") {
        return (
            <>
                <div>loading...</div>
            </>
        );
    }
    return (
        <>
            <div className="home-full">
                <div className="home-container">
                    <h3>Latest Article:</h3>
                    <div className="home-last-post" key={lastPost.id}>
                        <div>
                            <div>
                                <div className="home-main-title">
                                    {lastPost.mainTitle}
                                </div>
                                <div className="home-sub-title">
                                    {lastPost.subTitle}
                                </div>
                                <div className="-home-date">
                                    {lastPost.createdAt}
                                </div>
                            </div>
                            <img
                                className="home-main-img"
                                src={lastPost.fileUrl}
                                alt={lastPost.mainTitle}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: lastPost.postText,
                                }}
                            ></div>
                        </div>
                    </div>
                    <Link
                        to={"../article/" + lastPost.titleUrl}
                        onClick={() => setSelectedTitle(lastPost.titleUrl)}
                    >
                        <div className="home-more-button">
                            Read Full Article
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}
