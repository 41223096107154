import { Link } from "react-router-dom";

export default function ArticlesList({ titles, setSelectedTitle }) {
    if (JSON.stringify(titles) === "[]") {
        return (
            <>
                <div>loading...</div>
            </>
        );
    }
    return (
        <>
            <div className="article-list">
                {titles.map((title) => {
                    return (
                        <Link
                            to={"../article/" + title.titleUrl}
                            onClick={() => setSelectedTitle(title.mainTitle)}
                            key={title.id}
                        >
                            <div className="article-list-item">
                                <img
                                    className="article-list-img"
                                    src={title.fileUrl}
                                    alt={title.mainTitle}
                                />
                                <div className="article-list-title">
                                    <div className="article-list-main-title">
                                        {title.mainTitle}
                                    </div>
                                    <div className="article-list-sub-title">
                                        {title.subTitle}
                                    </div>
                                    <div className="article-list-date">
                                        {title.createdAt}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </>
    );
}
