import { useState } from "react";
import AthleteCreation from "./AthleteCreation";
import AthleteList from "./AthleteList";
import makeBouldersObject from "./makeBouldersObject";
import { useNavigate } from "react-router-dom";

export default function AthletesCreation({ setAthletesArray }) {
    let navigate = useNavigate();

    const [competition] = useState(JSON.parse(localStorage.getItem("final")));
    const [athletes, setAthletes] = useState([]);

    const addAthlete = (name) => {
        if (!name) return;
        const boulders = makeBouldersObject(competition.boulderAmount);
        const createdAt = Date.now();
        const athlete = { id: createdAt, name, boulders, createdAt };
console.log("boulders: ", boulders);

        setAthletes([...athletes, athlete]);
        localStorage.setItem(
            competition.categoryName,
            JSON.stringify(athletes)
        );
        setAthletesArray(athletes);
    };

    const handleSubmit = (athletes) => {
        setAthletesArray(athletes);
        navigate("/finals");
    };

    return (
        <>
            <div>
                <AthleteCreation addAthlete={addAthlete} />
                <AthleteList athletes={athletes} />
            </div>
            <button onClick={() => handleSubmit(athletes)}>
                Confirm finalists
            </button>
        </>
    );
}
