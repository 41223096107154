import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase-config";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { dateChanger, nameChanger } from "./randomFunctions/randomFunctions";
import { addDashAndSmallLetters } from "./textInputFunctions/tiFunctions";

export default function Article({ setSelectedTitle, selectedTitle }) {
    const [itemDoc, setItemDoc] = useState({});

    const { selected } = useParams();

    selectedTitle = addDashAndSmallLetters(selectedTitle);
    const queryTitle = selectedTitle ? selectedTitle : selected;

    const blogRef = collection(db, "blogposts");
    const q = query(blogRef, where("titleUrl", "==", queryTitle));

    useEffect(() => {
        const getItem = async () => {
            const docSnap = await getDocs(q);
            docSnap.forEach((item) => {
                setItemDoc({ ...item.data(), id: item.id });
            });
        };
        getItem();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (JSON.stringify(itemDoc) !== "{}") {
        const date = dateChanger(
            itemDoc.createdAt.seconds,
            itemDoc.createdAt.nanoseconds
        );

        return (
            <>
                <div>
                    <div className="main-title-article">
                        {itemDoc.mainTitle}
                    </div>
                    <div className="sub-title-article">{itemDoc.subTitle}</div>
                    <img
                        className="img-article"
                        src={itemDoc.fileUrl}
                        alt={itemDoc.mainTitle}
                    ></img>
                    <div
                        className="main-text"
                        dangerouslySetInnerHTML={{
                            __html: itemDoc.postText,
                        }}
                    ></div>
                    <br></br>
                    <div className="collaborator">{itemDoc.collaborator1}</div>
                    <br></br>
                    {itemDoc.nextArticle && (
                        <Link
                            to={"../article/" + itemDoc.nextArticle.titleUrl}
                            onClick={() =>
                                setSelectedTitle(itemDoc.nextArticle.mainTitle)
                            }
                            key={itemDoc.nextArticle.id}
                        >
                            <div className="link-next-article">
                                Keep reading: {itemDoc.nextArticle.mainTitle}
                            </div>
                        </Link>
                    )}
                    <br></br>
                    <div className="closing-article">
                        <div className="writer">
                            {nameChanger(itemDoc.author.name)}
                        </div>
                        <div className="date-article">{date}</div>
                    </div>
                </div>
            </>
        );
    }
}
